import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: 'AIzaSyDdpFfdeyjpuSl5QotvRxX3g0PH8PXkHsI',
  authDomain: 'mgnt-conference.firebaseapp.com',
  databaseURL: 'https://mgnt-conference.firebaseio.com',
  projectId: 'mgnt-conference',
  storageBucket: 'mgnt-conference.appspot.com',
  messagingSenderId: '3409403212',
  appId: '1:3409403212:web:672d8dee76f9ae75a6c3e9',
  measurementId: 'G-C9TYRSCJYH',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const { auth, database, storage } = firebase;

// collection references
/*
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')
*/

// export utils/refs
export {
  db,
  auth,
  database,
  storage,
  /*
  usersCollection,
  postsCollection,
  commentsCollection,
  likesCollection
  */
};
